<template>
  <div class="about">
    <Top></Top>
    <Fix></Fix>
    <div class="about-box">
      <div class="about-top">
          <div>公司介绍</div>
      </div>
      <div class="about-name">
        <div class="name1">杭州果老网数字科技有限公司</div>
        <div class="name2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杭州果老网数字科技有限公司，旨在以大数据、AI算法驱动，研发一套以提升商标注册成功率为市场入口的AI知识产权SaaS服务系统，将对企业的知产服务标准化、线上化、品质化、透明化，推动企业知识产权保护的数字化，降低企业知识产权保护的时间成本和确权维权成本，助力企业无形资产价值最大化。</div>
      </div>
      <!-- <div class="about-thing">
        <div class="about-thing1">知昇大事记</div>
        <div class="about-thing2"></div>
        <div class="about-thing3">
            <div v-for="(v,k) in things">
                <div>
                    <div class="thing-detail1">{{v.time}}</div>
                    <div class="thing-detail2">{{v.title}}</div>
                </div>
                <img :src="require('/static/images/aboutYu.png')" alt="">
            </div>
        </div>
      </div> -->
      <div class="about-bottom">
        <div class="about-bottom1">联系我们</div>
        <div class="about-bottom2">
          <div>浙江省杭州市滨江区浦沿街道浦沿路88号1幢3楼31977室</div>
          <!-- <div>400-630-2718（ 周一至周日 9:30 - 19:00 ）</div> -->
        </div>
        <div class="about-bottom3">
          <!-- <div>客服热线：15381065862</div> -->
          <div>商务合作：13774370416   毛先生</div>
        </div>
        <!-- <div class="about-bottom4">
          <div>客服邮箱：ayiusper@zhishengai.com</div>
          <div>商务邮箱：business@zhishengai.com</div>
        </div> -->
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
export default {
  name: 'about',
  data(){
    return{
        things:[{
          time:'2019年8月',
          title:'知昇（上海）人工智能科技有限公司成立'
        },{
          time:'2019年10月',
          title:'打造国内首个AI商标成功率评估应用'
        },{
          time:'2019年10月',
          title:'知识产权AI服务小程序“果老网”正式上线，广受好评'
        },{
          time:'2020年6月',
          title:'成立杭州子公司'
        },{
          time:'2020年12月',
          title:'与钉钉、顺利办达成战略合作'
        },{
          time:'2021年4月',
          title:'1.5升级版”驿企代理“上线'
        },{
          time:'2021年5月',
          title:'杭州高新区（滨江）知识产权公共服务平台上线果老网“AI商标成功率评估“服务模块'
        }]
    }
  },
  components: {
    Top,
    Foot,
    Fix
  },
  methods:{
  }
}

</script>

<style scoped>
.about-box{
  background-color: #fafafa;
  text-align: center;
}
  .about-top{
    background: url('../../../static/images/instrIN.png') no-repeat;
    background-size: cover;
    padding-top: 180px;
    width: 100%;
    height: 210px;
  }
  .about-top>div{
    font-size: 36px;
    color: #fff;
  }
  .about-name{
    width: 1110px;
    height: 268px;
    background: #FFFFFF;
    padding: 40px 45px 0;
    margin: 40px auto 30px;
    text-align: center;
  }
  .name1{
    font-size: 32px;
    margin-bottom: 39px;
    color: #111111;
  }
  .name2{
    color: #898989;
    font-size: 22px;
    font-weight: 400;
  }
  .about-thing{
    width: 1200px;
    height: 900px;
    margin: 0 auto 40px;
    padding: 40px 0 0 0;
    background: #FFFFFF;
  }
  .about-thing1{
    width: 200px;
    margin: 0 auto 30px;
    height: 45px;
    font-size: 32px;
    color: #323232;
    text-align: center;
  }
  .about-thing2{
    width: 1px;
    height: 40px;
    margin: 0 auto;
    background: #EEECEC;
  }
  .about-thing3{
    /* border:1px solid red; */
  }
  .about-thing3>div{
    position: relative;
    margin-top: -5px;
  }
  .about-thing3>div>div{
    position: absolute;
  }
  .about-thing3>div:nth-child(odd)>div{
    text-align: end;
  }
  .about-thing3>div:nth-child(even)>div{
    text-align: start;
  }
  .about-thing3>div>img{
    height: 100px;
  }
  .about-thing3>div:nth-child(odd)>div{
    right:636px;
  }
  .about-thing3>div:nth-child(even)>div{
    left: 636px;
    max-width: 537px;
  }
  .thing-detail1{
    font-size: 18px;
    color: #EB5E00;
    margin-bottom: 10px;
  }
  .thing-detail2{
    font-size: 20px;
  }
  .about-bottom{
    padding-top: 40px;
    width: 1200px;
    height: 287px;
    background: #fff;
    font-size: 14px;
    margin: 40px auto 60px;
  }
  .about-bottom1{
    width: 150px;
    height: 45px;
    text-align: center;
    font-size: 32px;
    margin: 0 auto 34px;
  }
  .about-bottom3{
    margin: 24px 0;
  }
</style>
